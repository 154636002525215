import {
  Assignment,
  AutoGraph,
  BarChart,
  // BubbleChart,
  ChevronLeft,
  ContentPasteSearch,
  Groups,
  QueryStats,
  SchoolRounded,
} from '@mui/icons-material';
import GradingIcon from '@mui/icons-material/Grading';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { ReactNode, useMemo } from 'react';

import { UserBaseRolesInner } from '@/generated/types/typescript-axios';
import { URL } from '@/types';

type Props = {
  accountRoles: UserBaseRolesInner[];
  open: boolean;
  handleMenuItemClick: (key: string, path: string) => void;
  onClose: () => void;
};

type DrawerItem = {
  key: string;
  text: string;
  icon: ReactNode;
  path: string;
  allowedRoles: string[];
};

const drawerMenuList: DrawerItem[] = [
  {
    key: 'handbookManagement',
    text: '手本管理',
    icon: <ContentPasteSearch />,
    path: URL.HANDBOOK_MANAGEMENT,
    allowedRoles: ['admin', 'teacher'],
  },
  {
    key: 'assignmentManagement',
    text: '提出課題管理',
    icon: <GradingIcon />,
    path: URL.ASSIGNMENT_MANAGEMENT,
    allowedRoles: ['admin', 'scorer', 'teacher'],
  },
  {
    key: 'userRanksManagement',
    text: '成績管理',
    icon: <PollOutlinedIcon />,
    path: URL.USER_RANKS_MANAGEMENT,
    allowedRoles: ['admin', 'teacher'],
  },
  {
    key: 'userManagement',
    text: 'ユーザー管理',
    icon: <Groups />,
    path: URL.USER_MANAGEMENT,
    allowedRoles: ['admin', 'teacher'],
  },
  // {
  //   key: 'courseManagement',
  //   text: 'コース管理',
  //   icon: <BubbleChart />,
  //   path: '',
  //   isAdminItem: true,
  // },
  {
    key: 'classroomManagement',
    text: '教室管理',
    icon: <SchoolRounded />,
    path: URL.CLASSROOM_MANAGEMENT,
    allowedRoles: ['admin'],
  },
  {
    key: 'handbook',
    text: 'お手本を見る',
    icon: <ContentPasteSearch />,
    path: URL.HANDBOOKS,
    allowedRoles: ['admin', 'scorer', 'student'],
  },
  {
    key: 'assignment',
    text: '課題を提出する',
    icon: <Assignment />,
    path: URL.ASSIGNMENT,
    allowedRoles: ['admin', 'student'],
  },
  {
    key: 'achievement',
    text: '成績を見る',
    icon: <QueryStats />,
    path: URL.RESULT,
    allowedRoles: ['admin', 'student'],
  },
  {
    key: 'wholeResult',
    text: '全体成績を見る',
    icon: <BarChart />,
    path: URL.WHOLE_RESULT,
    allowedRoles: ['admin', 'student', 'teacher'],
  },
  {
    key: 'overallResult',
    text: '総合評価を見る',
    icon: <AutoGraph />,
    path: URL.OVERALL_RESULT,
    allowedRoles: ['admin', 'student'],
  },
];

export default function SideMenu({ accountRoles, open, handleMenuItemClick, onClose }: Props) {
  const menuItems = useMemo(() => {
    return drawerMenuList.filter(({ allowedRoles }) =>
      accountRoles.some(({ role_name }) => allowedRoles.includes(role_name ?? ''))
    );
  }, [accountRoles]);

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: 1,
          ...theme.mixins.toolbar,
        })}
      >
        <IconButton onClick={onClose}>
          <ChevronLeft />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {menuItems.map(({ key, text, icon, path }) => (
          <Box key={key}>
            <ListItem disablePadding onClick={() => handleMenuItemClick(key, path)}>
              <ListItemButton sx={{ pl: 4, pr: 8, py: 2 }}>
                {icon}
                <ListItemText primary={text} sx={{ ml: 2 }} />
              </ListItemButton>
            </ListItem>
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
