import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import Auth from '@/components/Auth';
import RequireAllowedRoles from '@/components/RequireAllowedRoles';
import RequireSignUp from '@/components/RequireSignUp';
import Layout from '@/layout/Layout';
import i18n from '@/locales/i18n';
import SignUpConfirmation from '@/pages/account/SignUpConfirmation';
import SignUpRegistration from '@/pages/account/SignUpRegistration';
import Assignment from '@/pages/Assignment';
import AssignmentManagement from '@/pages/AssignmentManagement';
import Callback from '@/pages/callback/index';
import ClassroomManagement from '@/pages/ClassroomManagement';
import HandbookManagement from '@/pages/HandbookManagement';
import HandbookRegistration from '@/pages/HandbookRegistration';
import FontList from '@/pages/handbooks/FontList';
import HandbookDetail from '@/pages/handbooks/HandbookDetail';
import HandbookMonthList from '@/pages/handbooks/HandbookMonthList';
import MyPage from '@/pages/MyPage';
import NotFound from '@/pages/NotFound';
import Plan from '@/pages/Plan';
import UserManagement from '@/pages/UserManagement';
import UserRanksManagement from '@/pages/UserRanksManagement';

import PlanSubscribeCheckProvider from './components/PlanSubscribeCheckProvider';
import SignUpCheckProvider from './components/SignUpCheckProvider';
import { ToastContextProvider } from './components/ToastProvider';
import ClassroomRegistration from './pages/ClassroomRegistration';
import OverallResult from './pages/OverallResult';
import Result from './pages/Result';
import WholeResult from './pages/wholeResult';
import WholeResultDetails from './pages/wholeResult/details';
import HighScoresResult from './pages/wholeResult/highScores';
import { URL } from './types';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <ToastContextProvider>
          <Routes>
            <Route path={URL.CALLBACK} element={<Callback />} />
            <Route path="/" element={<Auth />}>
              {/* Sign-in users */}
              <Route path="/" element={<RequireSignUp />}>
                <Route path="" element={<Layout />}>
                  <Route path="/" element={<Navigate to="/handbooks" />} />
                  <Route path={URL.MY_PAGE} element={<MyPage />} />
                  {/* 手本 */}
                  <Route path={URL.HANDBOOKS} element={<HandbookMonthList />} />
                  <Route
                    path={`${URL.HANDBOOKS}/:year/:month/grade/:gradeId`}
                    element={<FontList />}
                  />
                  <Route path={`${URL.HANDBOOKS}/:year/:month/adult`} element={<FontList />} />
                  <Route
                    path={`/handbook-detail/:year/:month/grade/:gradeId/font/:fontId`}
                    element={<HandbookDetail />}
                  />
                  <Route
                    path={`/handbook-detail/:year/:month/adult/font/:fontId`}
                    element={<HandbookDetail />}
                  />
                  {/* 課題提出 */}
                  <Route
                    path="/assignment"
                    element={<RequireAllowedRoles allowedRoles={['student', 'admin']} />}
                  >
                    <Route path={`${URL.ASSIGNMENT}`} element={<Assignment />} />
                  </Route>
                  {/* 成績 */}
                  <Route
                    path="/result"
                    element={<RequireAllowedRoles allowedRoles={['student', 'admin']} />}
                  >
                    <Route path={`${URL.RESULT}`} element={<Result />} />
                  </Route>
                  {/* 全体成績 */}
                  <Route
                    path={`${URL.WHOLE_RESULT}`}
                    element={<RequireAllowedRoles allowedRoles={['student', 'admin']} />}
                  >
                    <Route path={`${URL.WHOLE_RESULT}`} element={<WholeResult />} />
                    <Route
                      path={`${URL.WHOLE_RESULT}/:year/:month/rank/:rankId/font/:fontId`}
                      element={<WholeResultDetails />}
                    />
                    <Route
                      path={`${URL.WHOLE_RESULT}/:year/:month/rank/:rankId/overall`}
                      element={<WholeResultDetails />}
                    />
                    <Route
                      path={`${URL.WHOLE_RESULT}/high-scores/:year/:month/font/:fontId`}
                      element={<HighScoresResult />}
                    />
                  </Route>
                  {/* 総合成績 */}
                  <Route
                    path="/overall-result"
                    element={<RequireAllowedRoles allowedRoles={['student', 'admin']} />}
                  >
                    <Route path={`${URL.OVERALL_RESULT}`} element={<OverallResult />} />
                  </Route>

                  {/* 管理者ページ */}
                  <Route
                    path="/management"
                    element={<RequireAllowedRoles allowedRoles={['admin', 'teacher']} />}
                  >
                    {/* ユーザー管理 */}
                    <Route path={URL.USER_MANAGEMENT} element={<UserManagement />} />
                    {/* 手本管理 */}
                    <Route path={URL.HANDBOOK_MANAGEMENT} element={<HandbookManagement />} />
                    <Route
                      path={`${URL.HANDBOOK_MANAGEMENT}/:year/:month`}
                      element={<HandbookManagement />}
                    />
                    <Route
                      path={`${URL.HANDBOOK_MANAGEMENT}/:year/:month/grade/:gradeId/font/:fontId`}
                      element={<HandbookRegistration />}
                    />
                    <Route
                      path={`${URL.HANDBOOK_MANAGEMENT}/:year/:month/adult/font/:fontId`}
                      element={<HandbookRegistration />}
                    />
                    {/* ユーザー成績管理 */}
                    <Route path={URL.USER_RANKS_MANAGEMENT} element={<UserRanksManagement />} />
                    {/* 教室管理 */}
                    <Route path={URL.CLASSROOM_MANAGEMENT} element={<ClassroomManagement />} />
                    <Route
                      path={`${URL.CLASSROOM_REGISTRATION}`}
                      element={<ClassroomRegistration />}
                    />
                  </Route>
                  {/* 課題管理 */}
                  <Route
                    path="/"
                    element={<RequireAllowedRoles allowedRoles={['admin', 'scorer']} />}
                  >
                    <Route path={URL.ASSIGNMENT_MANAGEMENT} element={<AssignmentManagement />} />
                    <Route
                      path={`${URL.ASSIGNMENT_MANAGEMENT}/:year/:month`}
                      element={<AssignmentManagement />}
                    />
                  </Route>
                </Route>
              </Route>
              {/* サインアップ */}
              <Route path="/" element={<SignUpCheckProvider />}>
                <Route path={URL.SIGN_UP_REGISTRATION} element={<SignUpRegistration />} />
                <Route path={URL.SIGN_UP_CONFIRMATION} element={<SignUpConfirmation />} />
              </Route>
              <Route path="/" element={<PlanSubscribeCheckProvider />}>
                <Route path={URL.PLAN} element={<Plan />} />
              </Route>
              {/* 404 */}
              <Route path="" element={<Layout />}>
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Routes>
        </ToastContextProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
